Runtime = {
	resetViewport: function() {
		if (System.Type.Mobile && !System.Type.Tablet) {
			document.querySelector('meta[name=viewport]').content='width=768';
		}
		
		if (document.scrollingElement) {
			document.scrollingElement.scrollLeft = 0;
			document.scrollingElement.scrollTop = 0;
		} else {
			document.body.scrollLeft = 0;
			document.body.scrollTop = 0;
		}
	},

	getQueryVariable: function(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
	}
};

(async function() {
	requirejs.config({
		baseUrl: '../../../external',

		paths: {
			lib: '../lib',
			core: '../core',
			platform: '../platform/browser',
			generic: '../platform/generic'
		}
	});

	window._ROOT = '../../../external/';

	
	await requireAsync([
		'../settings',
		'../bundles/bootstrap'
	]);

	await requireAsync([
		'../bundles/main',
	]);

	await requireAsync([
		'../bundles/platform/browser'
	]);


	Wrapper = true;
	Widgets.bootstrap();
	Application.initialize();

	new Runtime.Controller({
		client: Runtime.getQueryVariable('client'),
		salon: Runtime.getQueryVariable('salon'),
		path: Runtime.getQueryVariable('path')
	});

	document.body.dataset.channel = Settings.channel;
})()
